.container {
  height: 100vh;
}

.contain-inner {
  width: 20rem;
}

.img {
  width: 100%;
}